* {
  font-family: 'Calibri';
  user-select: none;
}

body {
  background-color: rgb(245, 245, 245);
}

.App {
  text-align: center;
}

.ContentArea {
  padding: 30px;
}

.App-logo {
  height: 0vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




input {
  padding: 15px;
  border: 0px;
  margin: 3px;
}

button {
  padding: 9px;
  border-radius: 0px;
  border: 0px;
  background-color: white;
  margin: 9px;
} button:hover {
  background-color: rgb(238, 238, 238);
} button:active {
  background-color: gray;
}

a {
  color:gray;
  text-decoration: none;
} a:hover {
  color: black;
} a:active {
  color: rgb(214, 214, 214);
}



.DocumentsListCard {
  width: 450px;
  max-width: 90%;
  padding: 12px;
  background-color: white;
  text-align: right;
}


.Create_r_Div {
  margin: auto;
  width: 600px;
  max-width: 90%;
}
.Create_r_Div input {
  width: 90%;
  margin: auto;
}
.Create_r_Div textarea {
  width: 90%;
  margin: auto;
  margin-top: 9px;
  padding: 15px;
  border: 0px;
  resize: vertical;
}
.Create_r_Div .InputKey {
  width: 90px;
  height: 4px;
}
.List_r_Div {
  width: 540px;
  max-width: 90%;
  margin: auto;
}
.List_r_Div .ListCardDiv {
  margin: auto;
  margin-bottom: 15px;
  width: 480px;
  max-width: 90%;
  padding: 9px;
  background-color: white;
  text-align: right;
}
.List_r_Div h3 {
  margin: 9px 9px 9px 9px;
}
.List_r_Div h3 span {
  margin-left: 24px;
  max-width: 50%;
}
.List_r_Div input {
  width: 120px;
  min-width: max-content;
}
.List_r_Div .HandleButton {
  height: 47px;
}
.ListCardActionBoxDiv {
  text-align: left;
}
.DocumentCodeSpan {
  font-size: small;
  color: gray;
  text-align: right;
}